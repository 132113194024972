import React from 'react'

import { MenuBar } from '../components'
import { Hero, Container } from '.'

const Page = ({ children }) => {
  return (
    <div className='page'>
      <MenuBar />
      <Hero classes='is-fullheight-with-navbar page__hero' innerClasses='page__content'>
        <Container>
          {children}
        </Container>
      </Hero>
    </div>
  )
}

export default Page
