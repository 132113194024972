import React from 'react'
import dateFormat from 'dateformat'

import { ArtFlow, Page } from '../containers'
import { PageHeader } from '../components'

import art from '../art'

const DailyFlowPage = () => {
  const now = new Date()
  const today = dateFormat(now, 'mmmm dS, yyyy')

  return (
    <Page>
      <PageHeader suptitle='Caedmond and The Inceptor present' title='Daily Flow' />
      <ArtFlow title={index => `Daily Flow, ${today} - Series ${index + 1}`} art={
        ({ row, column }) => art('flow', `flow-${row}-${today}`, {
          artOptions: { column }
        })
      } />
    </Page>
  )
}

export default DailyFlowPage
