import flowArtwork from './artworks/flow'

const artworks = {
  flow: flowArtwork
}

const cache = {}

const actuallyMakeArt = async (artwork, seed, options) => {
  const canvasSize = options.canvasSize || 1024

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.width = canvasSize
  canvas.height = canvasSize

  const artFunction = artworks[artwork]
  if (!artFunction) throw Error(`Artwork '${artwork}' doesn't exist`)

  const artOptions = options.artOptions || {}

  artFunction(seed, {
    ...artOptions,
    canvas,
    context
  })

  const blob = await new Promise((resolve, reject) => canvas.toBlob(resolve))
  const url = window.URL.createObjectURL(blob)

  return url
}

const makeArt = async (artwork, seed, options = {}) => {
  const key = JSON.stringify({ artwork, seed, options })

  if (!cache[key]) cache[key] = actuallyMakeArt(artwork, seed, options)
  return cache[key]
}

export default makeArt
