import React from 'react'
import classNames from 'classnames'

const Hero = ({ classes, head, headClasses, children, innerClasses, foot, footClasses }) => {
  return (
    <div className={classNames('hero', classes)}>
      {
        head
          ? (
            <div className={classNames('hero-head', headClasses)}>
              {head}
            </div>
          )
          : undefined
      }
      <div className={classNames('hero-body', innerClasses)}>
        {children}
      </div>
      {
        foot
          ? (
            <div className={classNames('hero-foot', footClasses)}>
              {foot}
            </div>
          )
          : undefined
      }
    </div>
  )
}

export default Hero
