import React, { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'

const Tile = ({ art, row, column }) => {
  const [src, setSrc] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  Promise.resolve(art({ row, column })).then(result => setSrc(result))

  return (
    <div className='art-flow__tile column'>
      <div className='card'>
        <div className='card-image'>
          <figure className='image is-1by1'>
            <img src={src} alt='' onClick={() => setModalOpen(true)} />
          </figure>
        </div>
      </div>
      <div className={classNames('modal', {
        'is-active': modalOpen
      })}>
        <div className='modal-background' onClick={() => setModalOpen(false)} />
        <div className='modal-content'>
          <p className='image is-1by1'>
            <img src={src} alt='' />
          </p>
        </div>
        <button className='modal-close is-large' onClick={() => setModalOpen(false)} />
      </div>
    </div>
  )
}

const Row = ({ art, title, number }) => {
  return (
    <div className='art-flow__row'>
      <div className='art-flow__row-columns columns is-mobile'>
        <Tile art={art} row={number} column={0} />
        <Tile art={art} row={number} column={1} />
        <Tile art={art} row={number} column={2} />
      </div>
      <div className='art-flow__row-description'>
        <div className='art-flow__row-title'>{title(number)}</div>
        <div className='art-flow__row-divider' />
      </div>
    </div>
  )
}

const ArtFlow = ({ art, title }) => {
  const [rows, setRows] = useState(1)
  const [height, setHeight] = useState(0)
  const [top, setTop] = useState(0)
  const [scroll, setScroll] = useState(window.scrollY)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
      setTop(node.getBoundingClientRect().top - document.getElementById('root').getBoundingClientRect().top)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }
    const handleScroll = () => setScroll(document.body.scrollTop)

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll, true)
    }
  })

  const itemHeight = Math.max(height / rows, window.innerWidth / 6)
  const heightToFill = windowHeight + scroll - top

  const rowsNeeded = Math.ceil(height > 0 ? Math.max(heightToFill / itemHeight + 1, rows) : 1)
  useEffect(() => setRows(rowsNeeded), [rowsNeeded])

  return (
    <div className='art-flow' ref={measuredRef}>
      {
        new Array(rowsNeeded).fill().map((_v, i) => {
          return (
            <Row art={art} title={title} number={i} key={i} />
          )
        })
      }
    </div>
  )
}

export default ArtFlow
