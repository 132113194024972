import React from 'react'

const PageHeader = ({ suptitle, title }) => {
  return (
    <div className='page-header'>
      <div className='page-header__suptitle'>{suptitle}</div>
      <div className='page-header__title'>{title}</div>
      <div className='page-header__divider' />
    </div>
  )
}

export default PageHeader
