import React from 'react'

import { Page, Content } from '../containers'
import { PageHeader } from '../components'

const AboutPage = () => {
  return (
    <Page>
      <PageHeader title='About Caedmond' />
      <Content>
        Hungarian Architectural Engineering student, who loves making art. I post about my studies, and my hobbies.
        <br />
        {'Enjoy ❤️'}
        <br />
        <br />
        <a href='https://www.deviantart.com/balintcsenge'>Deviantart</a>
        &nbsp;
        |
        &nbsp;
        <a href='https://www.instagram.com/balintcsenge_/'>Instagram</a>
      </Content>
    </Page>
  )
}

export default AboutPage
