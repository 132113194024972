import React from 'react'

import { ArtFlow, Page } from '../containers'
import { PageHeader } from '../components'

import art from '../art'

const FlowPage = () => {
  return (
    <Page>
      <PageHeader suptitle='Caedmond and The Inceptor present' title='Flow' />
      <ArtFlow title={index => `Series ${index + 1}`} art={
        ({ row, column }) => art('flow', `flow-${row}`, {
          artOptions: { column }
        })
      } />
    </Page>
  )
}

export default FlowPage
