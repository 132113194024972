import murmurRandom from 'murmur-random'

import palette from '../lib/palette'

const flowArtwork = (seed, options = {}) => {
  const gen = Array.isArray(seed) ? murmurRandom.raw(seed) : murmurRandom(seed, 2)
  const artPalette = palette(gen.subgen('art-palette'))

  const { canvas, context } = options

  const angle = gen.util.float(gen.value('art-angle'), 0, Math.PI)

  const [background, maybeForeground] = gen.util.pick(gen.value('background'), artPalette.greyscale, 2)
  const [accent, backupForeground] = gen.util.pick(gen.value('accent'), artPalette.color, 2)
  const foreground = maybeForeground || backupForeground

  const tileGen = gen.subgen('tile', options.column || 0)
  const size = canvas.width

  const angleMod = gen.util.int(tileGen.value('angle-mod'), 0, 3) * Math.PI * 0.5

  const cardboardSize = size / 64

  const fillWithCardboard = (offset) => {
    for (let i = -size; i < size; i += cardboardSize) {
      const cardboardGradient = context.createLinearGradient(i, 0, i + cardboardSize, 0)
      cardboardGradient.addColorStop(0, 'rgba(0, 0, 0, 0.6)')
      cardboardGradient.addColorStop(0.8, 'rgba(0, 0, 0, 0.2)')
      cardboardGradient.addColorStop(1, 'rgba(0, 0, 0, 0.6)')

      context.fillStyle = cardboardGradient

      context.fillRect(i, -size - offset, cardboardSize, size)
    }
  }

  context.fillStyle = foreground.css
  context.fillRect(0, 0, size, size)

  context.save() // save 1
  context.translate(size / 2, size / 2)
  context.rotate(angle + angleMod)

  const bgAccentAngle = gen.util.int(tileGen.value('background-accent-angle'), 0, 3) * Math.PI * 0.5
  const bgAccentOffset = gen.util.float(tileGen.value('background-accent-offset'), 0, size * 0.4)

  context.save() // save 2
  context.rotate(bgAccentAngle)

  context.fillStyle = accent.css
  context.fillRect(-size, -size - bgAccentOffset, size * 2, size)
  fillWithCardboard(bgAccentOffset)

  context.restore() // restore 2

  const brMajor = gen.util.point(tileGen.value('back-rect-major'), 0, size / 2)
  const brMinor = gen.util.point(tileGen.value('back-rect-minor'), size, size * 2)

  context.translate(-brMajor.x, -brMajor.y)

  context.fillStyle = background.css
  context.fillRect(0, 0, brMinor.x, brMinor.y)

  const fgMajor = gen.util.point(tileGen.value('front-rect-major'), 0, brMinor.x / 4, 0, brMinor.y / 4)
  const remaining = { x: brMinor.x - fgMajor.x, y: brMinor.y - fgMajor.y }
  const fgMinor = gen.util.point(tileGen.value('front-rect-minor'), remaining.x / 2, remaining.x, remaining.y / 2, remaining.y)

  // context.fillStyle = foreground.css
  // context.fillRect(fgMajor.x, fgMajor.y, fgMinor.x, fgMinor.y)

  context.save() // save 3

  const path = new window.Path2D()
  path.rect(fgMajor.x, fgMajor.y, fgMinor.x, fgMinor.y)
  context.clip(path)

  context.fillStyle = foreground.css
  context.fillRect(0, 0, size, size)

  // const fgAccentAngle = gen.util.int(tileGen.value('foreground-accent-angle'), 0, 3) * Math.PI * 0.5
  const fgAccentOffset = gen.util.float(tileGen.value('foreground-accent-offset'), 0, fgMinor.y / 3)

  context.translate(0, fgMajor.y + fgMinor.y / 2)
  // context.rotate(fgAccentAngle)

  context.fillStyle = accent.css
  context.fillRect(-size, -size - fgAccentOffset, size * 2, size)
  fillWithCardboard(fgAccentOffset)

  context.restore() // restore 3

  context.restore() // restore 1
}

export default flowArtwork
