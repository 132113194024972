import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import logo from '../logo.png'

class MenuBar extends React.Component {
  constructor () {
    super()

    this.state = {
      expanded: false
    }

    this.toggleExpanded = () => this.setState(state => ({ ...state, expanded: !state.expanded }))
  }

  render () {
    const { expanded } = this.state

    return (
      <nav className='navbar menu-bar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          {/* eslint-disable-next-line */}
          <a className='navbar-item'>
            <img src={logo} alt='' />
          </a>

          {/* eslint-disable-next-line */}
          <a role='button' className={classNames('navbar-burger burger', {
            'is-active': expanded
          })} onClick={this.toggleExpanded} aria-label='menu' aria-expanded='false'>
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        </div>

        <div className={classNames('navbar-menu', {
          'is-active': expanded
        })}>
          <div className='navbar-start'>
            <Link to='/' className='navbar-item'>Home</Link>

            <Link to='/flow' className='navbar-item'>Flow</Link>
            <Link to='/daily-flow' className='navbar-item'>Daily Flow</Link>
            <Link to='/about' className='navbar-item'>About</Link>
          </div>
        </div>
      </nav>
    )
  }
}

export default MenuBar
