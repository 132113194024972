import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'

import FlowPage from './pages/Flow'
import DailyFlowPage from './pages/DailyFlow'
import AboutPage from './pages/About'

import './app.sass'

function App () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact render={(...props) => <Redirect to='/flow' />} />
        <Route path='/flow' exact render={(...props) => <FlowPage {...props} />} />
        <Route path='/daily-flow' exact render={(...props) => <DailyFlowPage {...props} />} />
        <Route path='/about' exact render={(...props) => <AboutPage {...props} />} />

        <Route render={(...props) => <Redirect to='/' />} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
